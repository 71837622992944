/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { InertiaApp } from '@inertiajs/inertia-react';
import React from 'react';
import { AppProvider } from './Contexts/App';
import { ThemeProvider } from './Contexts/Theme';
import { CookiesProvider } from 'react-cookie';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

//Ant Design
import 'antd/dist/reset.css';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const App = () => {
    return (
        <>
            <CookiesProvider>
                <ThemeProvider>
                    <AppProvider>
                        <GoogleReCaptchaProvider
                            reCaptchaKey={
                                process.env?.GOOGLE_RECAPTCHA_SITE_KEY
                            }
                        >
                            <style>
                                {`
                                    .grecaptcha-badge {
                                        visibility: hidden;
                                    }
                                `}
                            </style>
                            <InertiaApp
                                initialPage={JSON.parse(app.dataset.page)}
                                // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                                resolveComponent={(pageString) =>
                                    import(`./Pages/${pageString}`).then(
                                        (module) => module.default,
                                    )
                                }
                            />
                        </GoogleReCaptchaProvider>
                    </AppProvider>
                </ThemeProvider>
            </CookiesProvider>
        </>
    );
};

export default App;
